import { Controller } from 'stimulus'
import { Kipu } from '@/constants/Kipu'

export default class SessionsController extends Controller {
  static targets = [
    'form',
    'login',
    'password',
    'submit',
    'touMessage',
  ]

  get domains () {
    if (this._domains === undefined) {
      this._domains = this.data.has('federated-domains')
        ? JSON.parse(this.data.get('federated-domains') || '[]')
        : Kipu.config?.auth?.federation?.domains || []
    }

    return this._domains || []
  }

  get federationEnabled () {
    if (this._federationEnabled === undefined) {
      this._federationEnabled = this.data.has('federated')
        ? this.data.get('federated') === 'true'
        : Kipu.config?.auth?.federation?.enabled
    }

    return this._federationEnabled
  }

  get isFederated () {
    if (this._isFederated !== undefined) return this._isFederated

    this.isFederated = this.federationEnabled

    return this._isFederated
  }

  set isFederated (value) {
    value = !!value
    if (this._isFederated !== value) {
      this._isFederated = value
      setTimeout(() => { this.updateDisplay() }, 0)
    }
    return value
  }

  connect () {
    this.updateDisplay()
  }

  onLoginInput () {
    this.isFederated = this.federationEnabled
  }

  submit (event) {
    this.submitTarget.disabled = true
    this.submitTarget.innerText = 'Working...'

    const email = this.loginTarget.value || ''

    // If the email is empty, use backend error handling to show message
    if (!email || !this.federationEnabled) return
    if (!email.includes('@')) return

    if (this.isFederated) event.preventDefault()

    this.updateFederationState()

    if (this.isFederated) {
      event.preventDefault()
      const url = this.data.has('federated-url')
        ? this.data.get('federated-url')
        : Kipu.config?.auth?.federation?.url
      if (url) window.location.href = url.replace('{email}', email)
      else {
        this.data.set('federated', 'false')
        this.isFederated = false
        console.error('Federation URL not configured')
        this.formTarget.submit()
      }
    }
  }

  updateDisplay () {
    if (this.isFederated) {
      this.submitTarget.innerText = this.data.get('federated-btn-text') || 'Continue'
      this.passwordTarget.classList.add('tw-hidden')
      this.touMessageTarget.classList.add('tw-hidden')
    } else {
      this.submitTarget.innerText = this.data.get('default-btn-text') || 'Sign In'
      this.passwordTarget.classList.remove('tw-hidden')
      this.touMessageTarget.classList.remove('tw-hidden')
    }

    this.submitTarget.disabled = false
  }

  updateFederationState () {
    const email = this.loginTarget.value || ''

    // If the email is empty or invalid, reset to default
    if (!this.federationEnabled || !email.includes('@')) {
      this.isFederated = this.federationEnabled
      return
    }

    const domain = email.split('@')[1]

    this.isFederated = this.domains.includes(domain)
  }
}
