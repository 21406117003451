import { Controller } from 'stimulus'
import { Kipu } from '@/constants/Kipu'

export default class PasswordResetController extends Controller {
  static targets = [
    'form',
    'email',
    'submit',
    'message'
  ]

  connect() {
    this.updateDisplay()
  }

  submit(event) {
    event.preventDefault()
    this.submitTarget.disabled = true
    this.submitTarget.innerText = 'Working...'

    const email = this.emailTarget.value || ''

    // If the email is empty, use backend error handling to show message
    if (!email) return this.formTarget.submit()

    if (!email.includes('@')) {
      this.messageTarget.textContent = 'Please enter a valid email address'
      this.submitTarget.disabled = false
      this.submitTarget.innerText = 'Send Reset Instructions'
      return
    }

    this.formTarget.submit()
  }

  updateDisplay() {
    this.submitTarget.disabled = false
    this.submitTarget.innerText = 'Send Reset Instructions'
  }
} 